<template>
    <Head>
        <title>{{ page.meta_title }}</title>
        <meta  name="description" v-if="page.meta_description" :content="page.meta_description">
    </Head>

    <Header :menu_items="menu_items" :page="page" :editable="editable" :website="website"/>

    <section class="bg-white dark:bg-black dark:text-white">
        <div class="">
            <div class="mx-auto max-w-7xl px-0 sm:px-6">
                <div class="mx-auto max-w-2xl py-5 mx-2 lg:max-w-none">
                    <slot name="content_title">
                    </slot>

                    <div class="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
                        <slot name="content_main1">
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <!-- footer -->
    <Footer :menu_items="menu_items" :page="page"  :website="website">
        <template #content_website_footer_left>
            <slot name="content_website_footer_left">
            </slot>
        </template>
        <template #content_website_footer_center>
            <slot name="content_website_footer_center">
            </slot>
        </template>
        <template #content_website_footer_right>
            <slot name="content_website_footer_right">
            </slot>
        </template>
    </Footer>
</template>


<script>

import Header from '@/Pages/Admin/Page/Templates/Partials/Header.vue';
import Footer from '@/Pages/Admin/Page/Templates/Partials/Footer.vue';
import {Head} from "@inertiajs/vue3";

export default {

    components: {
        Header,
        Footer,
        Head

    },
    props: {
        menu_items: Object,
        page: Object,
        editable: Boolean,
        website:Object,
    },

    data() {
        return {}
    },
}
</script>

